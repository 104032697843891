import React, { Component } from 'react'
import 'url-search-params-polyfill'

import styled, { createGlobalStyle } from 'styled-components'
import Countdown from 'react-countdown-now'

import Layout from '../components/layout'
import EthAddressForm from '../components/ethaddress-form'

const GlobalStyle = createGlobalStyle`
  html {
    background: #000;
    font-family: Arial, san-serif;
  }

`

const FormContainer = styled.div`
  margin: 1em;
  height: 3.5em;
  text-align: center;
`

const CountdownContainer = styled.div`
  margin: 15px;

  text-align: center;

  span {
    font-size: 60px;
    padding: 5px;
  }
`

const Text = styled.div`

  font-size: 15px;

  a {
    color: white;
  }
`

const Container = styled.div`

  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  border: 1px #000 solid;
  position: relative;
  background: #000;
  color: #fff;

  box-sizing: border-box;
  max-width: 640px;
  height: 100vh;
  margin: 0 auto;
`

const ErrorContainer = styled(Container)`
  text-align: center;
`

const Footnote = styled.p`
  margin-top: 2em;
  font-size: 0.75em;
`


export default class RegistrationPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      urlIsValid: false
    };
  }

  componentDidMount() {
    let urlParams = new URLSearchParams(this.props.location.search)

    if ( urlParams.get('asid') == null ) {
      this.setState({urlIsValid: false})
    } else {
      this.setState({urlIsValid: true})
    }
  }

  render() {
    let expirationDate = Date.UTC(2018,9,26,20,0)
    if ( this.state.urlIsValid && (Date.now() < expirationDate) ) {
      return (
        <Layout>
          <GlobalStyle/>
          <Container>
            <CountdownContainer>
              <Countdown date={expirationDate} />
            </CountdownContainer>
            <Text>
              <p>Thank you for attending Amnesia Scanner's performance at Unsound Krakow 2018.</p>
              <p>This unique URL can be redeemed for one AS Key* which can be used to access <a href="https://vault.amnesiascanner.net" target="_blank" rel="noopener noreferrer">vault.amnesiascanner.net</a></p>
              <p>To claim your AS Key, you must register by providing a valid ethereum address (public key). Enter your ethereum public key below, and you will be notified when the airdrop has completed.</p>
              <p>If you don't have an ethereum public key, follow the instructions <a href="https://www.youtube.com/watch?v=gHmh3yPYe68" target="_blank" rel="noopener noreferrer">here</a> and come back to this URL.</p>
              <p>Registration closes on Oct 26, 2018 22:00 CET</p>
            </Text>
            <FormContainer>
            <EthAddressForm location={this.props.location} />
            </FormContainer>
            <Footnote>* ERC-721 non-fungible token</Footnote>
          </Container>
        </Layout>
      )
    } else {
      return (
        <Layout>
          <GlobalStyle/>
          <ErrorContainer>
            <p>DACTYLIC HEXAMETER</p>
            <p>CATERED THEY CLIMAX</p>
            <p>CHIMERAL DECAY TEXT</p>
            <p>ACCELERATED THY MIX</p>
          </ErrorContainer>
        </Layout>
      )
    }
  }
}
