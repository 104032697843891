import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Form = styled.form`
  font-size: 1em;
  button, input {
    font-size: inherit;
    padding: 0.3em 0.4em;
    margin: 2.5px;
    box-sizing: content-box;
    border: 1px #888 solid;
  }

  input {
    font-family: courier;
    color: #333;
    width: 42ch;
    max-width: 60vw;
    background: white;
  }

  input::placeholder {
    color: #ccc;
  }

  button {
    margin-left: 5px;
    border: 1px #888 solid;
    box-shadow: none;
    color: #111;
    cursor: pointer;
    background: #fff;
  }

  button:disabled {
    background: #fff;
    border: 1px #999 solid;
    color: #999;
    cursor: not-allowed;
  }
`

class EthAddressForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      ethAddress: '',
      addressValid: false,
      success: false,
      failed: false
    };
  }

  handleUserInput = (e) => {
    const value = e.target.value;

    this.setState({ethAddress: value},
                  () => { this.validateAddress(value) });
  }

  handleSubmit = (e) => {
    e.preventDefault()

    let urlParams = new URLSearchParams(this.props.location.search)
    let mailchimpId = urlParams.get('asid')


    axios({
      method: 'post',
      url: '/.netlify/functions/register',
      data: {
        asid: mailchimpId,
        ethAddress: this.state.ethAddress
      }
    }).then(() => {
      this.setState({
        success: true
      })
    }).catch((e) => {
      this.setState({
        failed: true
      })
    })
  }

  validateAddress(value) {
    let addressValid = value.match(/^0[xX][0-9a-fA-F]{40}$/i);

    this.setState({addressValid: addressValid});
  }

  render () {
    if (this.state.success) {
      return (
        <div>
          <p>You have successfully registered for one AS Key.</p>
          <p>You will be notified via e-mail when your AS Key is available for use.</p>
        </div>
      )
    } else if (this.state.failed) {
      return <p>This URL is invalid, or has already been redeemed.</p>
    }
    return (
      <Form>
        <input type="text"
          name="ethaddress"
          placeholder="0x0000000000000000000000000000000000000000"
          value={this.state.ethAddress}
          onChange={this.handleUserInput}
        />
        <button type="submit" className="btn" onClick={this.handleSubmit} disabled={!this.state.addressValid}>
          Register
        </button>
      </Form>
    )
  }
}

export default EthAddressForm;
